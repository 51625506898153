<template>
  <div>
    <p>{{ $t('onboarding.bankAccountInformation.title') }}</p>
    <p>{{ $t('onboarding.bankAccountInformation.desc') }}</p>
    <v-form
      v-model="isFormValid"
      @submit.prevent="submit"
    >
      <!-- BANK ACCOUNT OWNER -->
      <v-text-field
        id="bank-account-owner"
        type="text"
        :rules="rules.fullname"
        :value="data.financialData.refBankAccOwner"
        required
        :label="`${$t('onboarding.bankAccountInformation.fields.refBankAccOwner')} *`"
        outlined
        disabled
      ></v-text-field>

      <!-- BANK ACCOUNT IBAN -->
      <v-text-field
        class="uppercase"
        id="bank-account-iban"
        type="text"
        :rules="this.validIban ? rules.iban : [$t('validators.iban.invalid')]"
        v-model="data.financialData.refBankIban"
        v-mask="'AA## #### #### #### #### ####'"
        :label="`${$t('onboarding.bankAccountInformation.fields.refBankIban')} *`"
        outlined
        required
        @input="ibanChange"
        validate-on-blur
      ></v-text-field>

      <div v-if="bankInfoFromIban">
        <!-- BANK ACCOUNT NAME - only for informational purposes-->
        <v-text-field
          id="bank-account-bank"
          type="text"
          :value="bankInfoFromIban.name"
          :label="`${$t('onboarding.bankAccountInformation.fields.refBankName')} *`"
          outlined
          disabled
        ></v-text-field>

        <!-- BANK ACCOUNT BIC -->
        <v-text-field
          id="bank-account-bic"
          type="text"
          :value="bankInfoFromIban.bic"
          :label="`${$t('onboarding.bankAccountInformation.fields.refBankBic')} *`"
          outlined
          disabled
        ></v-text-field>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import { fullname, iban } from '@/utils/validators';
import debounce from 'lodash.debounce';

export default {
  name: 'BankAccountInformation',
  directives: {
    mask,
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        fullname,
        iban,
      },
      validIban: false,
    };
  },
  computed: {
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
    ...mapGetters('onboarding', [
      'editMode',
    ]),
    ...mapGetters('user', [
      'bankInfoFromIban',
    ]),
  },
  created() {
    const { firstName, lastName } = this.data.personalData;
    this.data.financialData.refBankAccOwner = `${firstName} ${lastName}`;
    if (this.bankInfoFromIban) {
      this.validIban = true;
    } else {
      this.ibanChange();
    }
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
      'validateIban',
      'resetBankInfoFromIban',
    ]),
    async submit() {
      this.data.financialData.refBankBic = this.bankInfoFromIban.bic;
      const input = {
        investor: {
          personal: {
            bankAccountInformation: {
              refBankIban: this.data.financialData.refBankIban,
              refBankBic: this.bankInfoFromIban.refBankBic,
              refBankAccOwner: this.data.financialData.refBankAccOwner,
            },
          },
        },
        mainStage: 'personal',
        subStage: 'bankAccountInformation',
      };
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (this.editMode) {
          this.$store.dispatch('onboarding/editMode', null);
          this.$router.push({
            name: 'Onboarding.Personal',
            params: {
              step: 'Overview',
            },
          });
        } else {
          this.setSubStage('sourceofwealthinformation');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
    validateBankIban: debounce(async function () {
      try {
        await this.validateIban({
          refBankIban: this.data.financialData.refBankIban.replace(/\s/g, ''),
        });
        this.validIban = true;
      } catch (error) {
        this.resetBankInfoFromIban();
        this.validIban = false;
        this.$notify.error(error);
      }
    }, 1000),
    ibanChange() {
      const { refBankIban } = this.data.financialData;
      if (refBankIban && refBankIban.replace(/\s/g, '').length >= 16) {
        this.validateBankIban();
      } else {
        this.resetBankInfoFromIban();
        this.validIban = false;
      }
    },
  },
};
</script>

<style>
.uppercase input {
  text-transform: uppercase;
}
</style>
